import { useQuery } from '@tanstack/react-query';
import apiClient from '@/lib/api/apiClient';

export interface ISubscription {
  type: 'apple_appstore' | 'google_play' | 'pg';
  description: string;
  subscribedAt: string;
  unsubscribedAt: string;
  willRenewalAt: string;
  product: {
    name: string;
    uid: string;
    basicDuration: number;
    basicDurationUnit: string;
    basicPrice: number;
  };
  periodOfUse: {
    startAt: string;
    endAt: string;
  };
  renewalFailure?: {
    code: string;
    message: string;
  };
  createdAt: string;
  updatedAt: string;
}

export const queryKey = ['subscription'];

export const useSubscriptionQuery = () => {
  const { data, isLoading, error } = useQuery(
    [...queryKey],
    async () => {
      const { data } = await apiClient.get<{
        data: ISubscription
      }>(`/subscription`);

      return data as ISubscription;
    },
    {
      retry: false,
      refetchOnMount: true,
      cacheTime: 0,
    },
  );

  return { data, isLoading, error };
};
